.grid-container {
    display: grid;
    grid-template-areas:
    "header header header"
    "sidebar main main"
    "footer footer footer";
    grid-template-columns: 240px 1fr;
    grid-template-rows: auto 1fr auto;
    height: 100vh;
    gap: 0;
}

.header {
    grid-area: header;
    z-index: 1000; /* Ensure header stays on top */
}

.sidebar {
    grid-area: sidebar;
    background-color: #f0f0f0;
    padding: 20px;
    padding-top: 40px; /* Add top padding to align with main content */
}

.main-content {
    grid-area: main;
}

.footer {
    grid-area: footer;
}