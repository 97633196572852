.sidebar {
    width: 100%; /* Take full width of its grid area */
    height: 100%; /* Take full height of its grid area */
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.sidebar-button {
    width: 100%;
    padding: 10px;
    text-align: left;
    background-color: #4285f4; /* Google Blue */
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sidebar-button:hover {
    background-color: #3367d6; /* Darker shade on hover */
}