/* Footer.css */
.footer {
    grid-area: footer;
    background-color: #333;
    color: white;
    text-align: center;
    padding: 20px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;
    z-index: 2000;
    min-height: 60px; /* Minimum height */
    height: auto; /* Allow height to grow based on content */
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Add margin to parent container to prevent content overlap */
.page-container {
    position: relative;
    min-height: 100vh;
    margin-bottom: calc(60px + 2rem); /* Base footer height + extra space */
}

/* Ensure content doesn't get hidden */
.content-wrap {
    padding-bottom: calc(60px + 4rem); /* Base footer height + extra padding */
}

/* Make footer content responsive */
.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .footer {
        padding: 15px;
    }

    .page-container {
        margin-bottom: calc(80px + 2rem); /* Increased for mobile */
    }

    .content-wrap {
        padding-bottom: calc(80px + 4rem); /* Increased for mobile */
    }
}