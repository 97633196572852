/* HomePage.css */
.page-container {
    position: relative;
    min-height: 100vh;
}

.content-wrap {
    padding-bottom: 60px; /* Must be equal to or greater than footer height */
}

/* Keep your existing styles */
.home-page {
    background-color: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0 20px;
    position: relative;
    overflow: hidden;
}

.content {
    text-align: center;
    z-index: 2;
}

.title {
    font-size: 42px;
    color: #333;
    margin-bottom: 20px;
}

.subtitle {
    font-size: 18px;
    color: #666;
    margin-bottom: 30px;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.button {
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.get-started {
    background-color: #00d1b2;
    color: white;
}

.get-started:hover {
    background-color: #00b29a;
}

.explore {
    background-color: transparent;
    color: #00d1b2;
    border: 1px solid #00d1b2;
}

.explore:hover {
    background-color: #00d1b2;
    color: white;
}