/* App.css */

.App {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Arial', sans-serif;
}

h1 {
  color: #333;
}

ul {
  list-style: none;
  padding: 0;
}

li {
  background-color: #f7f7f7;
  border: 1px solid #ddd;
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
}

form {
  margin-bottom: 30px;
}

input {
  padding: 10px;
  margin: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: calc(100% - 22px);
}

button {
  padding: 10px 20px;
  background-color: #5cb85c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #4cae4c;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.chart-container {
  width: 90%; /* Adjust the width as needed */
  height: 400px; /* Adjust the height as needed */
  margin: auto; /* Center the chart in the middle of the page */
}

.price-chart-container {
  padding: 16px;
  background: #1d1f22; /* Example dark background */
  border-radius: 8px;
}


.app-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.grid-container {
  display: grid;
  grid-template-areas:
    'header header'
    'sidebar main'
    'footer footer';
  grid-template-columns: 240px 1fr; /* sidebar width and main content taking the rest */
  grid-template-rows: 60px 1fr 30px; /* header height, content, and footer height */
  height: 100vh;
  gap: 20px;
  padding: 20px;
}

.header {
  grid-area: header;
  background-color: #333;
  color: white;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sidebar {
  grid-area: sidebar;
  background-color: #f3f3f3;
  padding: 20px;
}

.main-content {
  grid-area: main;
  padding: 20px;
}

.footer {
  grid-area: footer;
  background-color: #333;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}